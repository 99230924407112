body {
    margin: 0;
    padding: 0;
    font-family: 'CircularStd', Roboto, Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: pink;
  }
  
  @font-face {
    font-family: 'VGA';
      src:  url('../fonts/PerfectDOSVGA437.woff2') format('woff2'),
            url('../fonts/PerfectDOSVGA437.woff') format('woff');
      src:  url('../fonts/PerfectDOSVGA437.ttf') format('truetype'); /* Safari, Android, iOS */
      font-weight: normal;
      font-style: normal;
    
  }

  p {
    font-weight: 400;
  }

  ul {
    font-weight: 400;
  }

  li {
    font-weight: 400;
  }

  a {
    background-color: transparent;
    /* text-decoration: none; */
    color:inherit;
  }

  .full{
    height:100%;
    width:100%;
    overflow:hidden; /* or overflow:auto; if you want scrollbars */
    position: fixed;
    z-index: -1;
  }

  .Toastify__toast {
    background: #F6F513;
    color: #0027FF;
    border: solid #0027FF 4px;
    font-family: "CircularStd";
    font-size: 17px;
  }

  textarea {
    font-family: 'CircularStd', Roboto, Arial, Helvetica, sans-serif;
    font-size: 15px;
    padding-left: 10px;
  }

  *:focus {
    outline: none;
}