.TextEditorWrapper {
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
}

.Editor {
    padding-left: 20px;
    padding-right: 20px;
}

.Toolbar {
    border-bottom: solid #0027FF 4px;
}