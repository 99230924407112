#canvas-elements {
    overflow: hidden;
}

.canvas-element {
    background: #fff;
    width: 50%;
}

.canvas-element.dragging {
    border: 2px solid blue;
}